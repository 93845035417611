<template>
<section id="testimonial" class="section">
      <div class="container">
        <div class="section-header">
          <p class="btn btn-subtitle wow fadeInDown" data-wow-delay="0.2s">Reviews</p>
          <h2 class="section-title">ความคิดเห็น จากผู้ที่สนใจเข้าร่วม</h2>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12">
            <carousel id="testimonials" class="touch-slider" :settings="settings" :breakpoints="breakpoints">
              <slide key="0">
                <div class="item">
                  <div class="testimonial-item">
                    <div class="author">
                      <div class="img-thumb">
                        <img src="@/assets/img/testimonial/img1.png" alt="">
                      </div>
                    </div>
                    <div class="content-inner">
                      <p class="description">Appropriately implement one-to-one catalysts for change vis-a-vis wireless catalysts for change. Enthusiastically architect adaptive. Appropriately implement one-to-one catalysts for change vis-a-vis wireless catalysts for change. Enthu.</p>
                      <div class="author-info">
                        <h2><a href="#">Mark Parker</a></h2>
                        <span>UIdeck</span>
                      </div>
                    </div>
                  </div>
                </div>
              </slide>
              <slide key="1">
                <div class="item">
                  <div class="testimonial-item">
                    <div class="author">
                      <div class="img-thumb">
                      <img src="@/assets/img/testimonial/img2.png" alt="">
                      </div>
                    </div>
                    <div class="content-inner">
                      <p class="description">Appropriately implement one-to-one catalysts for change vis-a-vis wireless catalysts for change. Enthusiastically architect adaptive. Appropriately implement one-to-one catalysts for change vis-a-vis wireless catalysts for change. Enthu.</p>
                      <div class="author-info">
                        <h2><a href="#">Mark Parker</a></h2>
                        <span>Tesla Motors</span>
                      </div>
                    </div>
                  </div>
                </div>
              </slide>
              <slide key="2">
                <div class="item">
                  <div class="testimonial-item">
                    <div class="author">
                      <div class="img-thumb">
                      <img src="@/assets/img/testimonial/img3.png" alt="">
                      </div>
                    </div>
                    <div class="content-inner">
                      <p class="description">Appropriately implement one-to-one catalysts for change vis-a-vis wireless catalysts for change. Enthusiastically architect adaptive. Appropriately implement one-to-one catalysts for change vis-a-vis wireless catalysts for change. Enthu.</p>
                      <div class="author-info">
                        <h2><a href="#">Mark Parker</a></h2>
                        <span>Envato Customer</span>
                      </div>
                    </div>
                  </div>
                </div>
              </slide>
              <slide key="3">
                <div class="item">
                  <div class="testimonial-item">
                    <div class="author">
                      <div class="img-thumb">
                        <img src="@/assets/img/testimonial/img2.png" alt="">
                      </div>
                    </div>
                    <div class="content-inner">
                      <p class="description">Appropriately implement one-to-one catalysts for change vis-a-vis wireless catalysts for change. Enthusiastically architect adaptive. Appropriately implement one-to-one catalysts for change vis-a-vis wireless catalysts for change. Enthu.</p>
                      <div class="author-info">
                        <h2><a href="#">Mark Parker</a></h2>
                        <span>GrayGris Inc.</span>
                      </div>
                    </div>
                  </div>
                </div>
              </slide>
              <slide key="4">
                <div class="item">
                  <div class="testimonial-item">
                    <div class="author">
                      <div class="img-thumb">
                        <img src="@/assets/img/testimonial/img1.png" alt="">
                      </div>
                    </div>
                    <div class="content-inner">
                      <p class="description">Appropriately implement one-to-one catalysts for change vis-a-vis wireless catalysts for change. Enthusiastically architect adaptive. Appropriately implement one-to-one catalysts for change vis-a-vis wireless catalysts for change. Enthu.</p>
                      <div class="author-info">
                        <h2><a href="#">- Alex Dattilo</a></h2>
                        <span>CEO Optima Inc</span>
                      </div>
                    </div>
                  </div>
                </div>
              </slide>
            </carousel>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
import { Options, Vue } from 'vue-class-component'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

import 'vue3-carousel/dist/carousel.css'

@Options({
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation
  },
  data: () => ({
    // carousel settings
    settings: {
      itemsToShow: 1,
      // snapAlign: 'center',
      autoplay: 3000,
      transition: 1000,
      wrapAround: true
    },
    // breakpoints are mobile first
    // any settings not specified will fallback to the carousel settings
    breakpoints: {
      // 700px and up
      // 700: {
      //   itemsToShow: 3,
      //   snapAlign: 'center'
      // },
      // // 1024 and up
      // 1024: {
      //   itemsToShow: 3,
      //   snapAlign: 'start'
      // }
    }
  })
})
export default class Testimonial extends Vue {}
</script>
