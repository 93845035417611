<template>

  <router-view  />

  <!-- Footer Section Start -->
    <footer>
      <!-- Footer Area Start -->
      <section class="footer-Content">
        <div class="container">
          <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-6 col-mb-12">
              <img src="@/assets/img/logo.png" alt="">
              <div class="textwidget">
                <p>บริษัท AeraLife เปิดมาตั้งแต่ปี 2020 ดำเนินการในธุรกิจการสร้าง Application และ โปรแกรมคอมพิวเตอร์ และเรามุ่งหวังจะสร้างเทคโนโลยีเพื่อความสุขของผู้คน</p>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-6 col-mb-12">
            <!--   <div class="widget">
                <h3 class="block-title">Create a Free Account</h3>
                <ul class="menu">
                  <li><a href="#">Sign In</a></li>
                  <li><a href="#">About Us</a></li>
                  <li><a href="#">Pricing</a></li>
                  <li><a href="#">Jobs</a></li>
                </ul>
              </div> -->
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-6 col-mb-12">
              <div class="widget">
                <h3 class="block-title">Resource</h3>
                <ul class="menu">
                  <!-- <li><router-link :to="{ name: 'download'}">Social</router-link></li> -->
                  <li><a href="https://play.google.com/store/apps/details?id=aeralife.com.aeralife">Social</a></li>
                  <li><a href="https://market.aeralife.com/" target="_blank">Market Place</a></li>
                  <li><a href="http://bcdemo.aeralife.com"  target="_blank">Blockchain(Demo)</a></li>
                  <!-- <li><a href="#">Documentation</a></li> -->
                </ul>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-6 col-mb-12">
              <div class="widget">
                <!-- <h3 class="block-title">Support</h3> -->
                <h3 class="block-title">contact</h3>
                <ul class="menu">
                  <li><a href="https://lin.ee/4GgXiaeH">Line: @aeralife</a></li>
                  <li><a href="https://web.facebook.com/aeracoins" target="_blank">FB: @aeracoins</a></li>
                  <!-- <li><a href="#">Privacy Policy</a></li>
                  <li><a href="#">Help</a></li> -->
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Copyright Start  -->
        <div class="copyright">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="site-info float-left">
                  <p>&copy; 2022 - Powered by <a href="http://dee-soft.com" rel="nofollow">DeeSoft</a></p>
                </div>
                <!-- <div class="float-right">
                  <ul class="footer-social">
                    <li><a class="facebook" href="https://web.facebook.com/aeracoins" target="_blank"><i class="lni-facebook-filled"></i></a></li>
                    <li><a class="twitter" href="#"><i class="lni-twitter-filled"></i></a></li>
                    <li><a class="linkedin" href="#"><i class="lni-linkedin-fill"></i></a></li>
                    <li><a class="google-plus" href="#"><i class="lni-google-plus"></i></a></li>
                  </ul>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      <!-- Copyright End -->
      </section>
      <!-- Footer area End -->

    </footer>
    <!-- Footer Section End -->

    <!-- Go To Top Link -->
    <a href="#" class="back-to-top">
      <i class="lni-chevron-up"></i>
    </a>

    <!-- Preloader -->
    <div id="preloader">
      <div class="loader" id="loader-1"></div>
    </div>

</template>

<script>

import { Options, Vue } from 'vue-class-component'
import { useRoute } from 'vue-router'
import { WOW } from 'wowjs'
import jQuery from 'jquery'
global.jQuery = jQuery

@Options({

  data () {
    return {
      navbgShow: true,
      timeCopyright: '',
      scrollPosition: 0,
      logomin: false,
      form_loading: false,
      email_name: '',
      email_email: '',
      email_subject: '',
      email_message: '',
      capchaCheck: false,
      scroll_offset: 200
    }
  },
  mounted () {
    // jQuery(function () {
    jQuery('#preloader').fadeOut()

    const wow = new WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: true,
      live: false
    })
    wow.init()

    jQuery(window).on('load', function () {
      // jQuery('body').scrollspy({
      //   target: '.navbar-collapse',
      //   offset: 195
      // })
      // console.log('jquery loaded')
      // jQuery('#preloader').fadeOut()

      // jQuery('body').scrollspy({
      //   target: '.navbar-collapse',
      //   offset: 195
      // })

      jQuery(window).on('scroll', function () {
        if (jQuery(window).scrollTop() > 100) {
          jQuery('.fixed-top').addClass('menu-bg')
        } else {
          jQuery('.fixed-top').removeClass('menu-bg')
        }
      })

      var offset = this.scroll_offset
      var duration = 500
      jQuery(window).scroll(function () {
        if (jQuery(this).scrollTop() > offset) {
          jQuery('.back-to-top').fadeIn(400)
        } else {
          jQuery('.back-to-top').fadeOut(400)
        }
      })

      jQuery('.back-to-top').on('click', function (event) {
        event.preventDefault()
        jQuery('html, body').animate({
          scrollTop: 0
        }, 600)
        return false
      })

      if (jQuery(window).width() <= 768) {
        jQuery('.navbar-collapse a').on('click', function () {
          jQuery('.navbar-collapse').collapse('hide')
        })
      } else {
        jQuery('.navbar .navbar-inverse a').off('click')
      }
    })

    // setTimeout(() => this.anchorHashCheck(), 10000)
  },
  computed: {
    currentRouteName () {
      return this.$route.name
    }
  },
  methods: {
    // close_toggle () {
    //   if (jQuery(window).width() <= 768) {
    //     jQuery('.navbar-collapse a').on('click', function () {
    //       jQuery('.navbar-collapse').collapse('hide')
    //     })
    //   } else {
    //     jQuery('.navbar .navbar-inverse a').off('click')
    //   }
    // },
    handleScroll (event) {
      this.scrollPosition = window.scrollY
      if (this.scrollPosition > 20) {
        this.logomin = true
      } else {
        this.logomin = false
      }
    }
  }
})
export default class app extends Vue {}

</script>
