import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ArticlesView from '../views/ArticlesView.vue'
import ArticleView from '../views/ArticleView.vue'
import AboutView from '../views/AboutView.vue'
import PlanView from '../views/PlanView.vue'
import Phontom from '../components/articles/Phontom.vue'
import Satang from '../components/articles/Satang.vue'
import SatangDeposite from '../components/articles/SatangDeposite.vue'
import SatangTrade from '../components/articles/SatangTrade.vue'
import SatangWithdraw from '../components/articles/SatangWithdraw.vue'
import RaydiumSwap from '../components/articles/RaydiumSwap.vue'
import RaydiumTrade from '../components/articles/RaydiumTrade.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    alias: '/home'
  },
  // {
  //   path: '/home',
  //   name: 'home',
  //   component: HomeView
  // },
  {
    path: '/articles',
    name: 'articles',
    component: ArticlesView
  },
  {
    path: '/article',
    name: 'article',
    component: ArticleView,
    children: [
      {
        path: '1/:name',
        name: 'สร้างกระเป๋าเงินดิจิตอล',
        component: Phontom
      },
      {
        path: '2/:name',
        name: 'สมัครบัญชีสำหรับแลกเปลี่ยนเหรียญ',
        component: Satang
      },
      {
        path: '3/:name',
        name: 'การฝากเงินเข้าSatang',
        component: SatangDeposite
      },
      {
        path: '4/:name',
        name: 'tradesatang',
        component: SatangTrade
      },
      {
        path: '5/:name',
        name: 'withdrawcrypto',
        component: SatangWithdraw
      },
      {
        path: '6/:name',
        name: 'swapaeratoken',
        component: RaydiumSwap
      },
      {
        path: '7/:name',
        name: 'tradeaeratoken',
        component: RaydiumTrade
      }
    ]
  },
  {
    path: '/plan',
    name: 'plan',
    component: PlanView
  },
  {
    path: '/aboutus',
    name: 'aboutus',
    component: AboutView
  }
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
