<template>
  <section id="screenshots" class="screens-shot section">
      <div class="container">
        <div class="section-header">
          <p class="btn btn-subtitle wow fadeInDown" data-wow-delay="0.2s">Screenshots</p>
          <h2 class="section-title">App Screens</h2>
        </div>
        <div class="">
            <carousel id="team-carousel" class="touch-slider" :settings="settings" :breakpoints="breakpoints">
              <slide key="0">
                <div class="item">
                  <div class="screenshot-thumb">
                    <img class="img-fluid" src="@/assets/img/screenshot/token_trad.jpg" alt="">
                  </div>
                </div>
              </slide>
              <slide key="1">
                <div class="item">
                  <div class="screenshot-thumb">
                    <img class="img-fluid" src="@/assets/img/screenshot/token_swap.jpg" alt="">
                  </div>
                </div>
              </slide>
              <slide key="2">
                <div class="item">
                  <div class="screenshot-thumb">
                    <img class="img-fluid" src="@/assets/img/screenshot/token_solana.jpg" alt="">
                  </div>
                </div>
              </slide>
              <slide key="3">
                <div class="item">
                  <div class="screenshot-thumb">
                    <img class="img-fluid" src="@/assets/img/screenshot/app_singin.jpg" alt="">
                  </div>
                </div>
              </slide>
              <slide key="4">
                <div class="item">
                  <div class="screenshot-thumb">
                    <img class="img-fluid" src="@/assets/img/screenshot/app_feed.jpg" alt="">
                  </div>
                </div>
              </slide>
              <slide key="5">
                <div class="item">
                  <div class="screenshot-thumb">
                    <img class="img-fluid" src="@/assets/img/screenshot/app_noti.jpg" alt="">
                  </div>
                </div>
              </slide>
              <slide key="6">
                <div class="item">
                  <div class="screenshot-thumb">
                    <img class="img-fluid" src="@/assets/img/screenshot/market_product.jpg" alt="">
                  </div>
                </div>
              </slide>
              <slide key="7">
                <div class="item">
                  <div class="screenshot-thumb">
                    <img class="img-fluid" src="@/assets/img/screenshot/market_cart.jpg" alt="">
                  </div>
                </div>
              </slide>
              <slide key="8">
                <div class="item">
                  <div class="screenshot-thumb">
                    <img class="img-fluid" src="@/assets/img/screenshot/market_products.jpg" alt="">
                  </div>
                </div>
              </slide>

              <template #addons>
                <Pagination />
              </template>
            </carousel>
        </div>
      </div>
    </section>
</template>

<script>
import { Options, Vue } from 'vue-class-component'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

import 'vue3-carousel/dist/carousel.css'

@Options({
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation
  },
  data: () => ({
    // carousel settings
    settings: {
      itemsToShow: 1,
      // snapAlign: 'center',
      autoplay: 3000,
      transition: 1000
      // wrapAround: true
    },
    // breakpoints are mobile first
    // any settings not specified will fallback to the carousel settings
    breakpoints: {
      // 700px and up
      700: {
        itemsToShow: 3,
        snapAlign: 'center'
      },
      // 1024 and up
      1024: {
        itemsToShow: 4,
        snapAlign: 'start'
      }
    }
  })
})
export default class Team extends Vue {
}
</script>

<style>
  .carousel__pagination{
    padding-left: 0;
  }
</style>
