import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import BootstrapVue3 from 'bootstrap-vue-3'
import VueSmoothScroll from 'vue3-smooth-scroll'
import VueRecaptcha from 'vue3-recaptcha-v2'
// import breadcrumbs from 'vue-3-breadcrumbs'
import PrimeVue from 'primevue/config'
import Breadcrumb from 'primevue/breadcrumb'

import './assets/scss/main.scss'
import 'bootstrap'

const app = createApp(App)

// app.use(registerScrollSpy)
// app.use(BootstrapVue3)
app.use(VueRecaptcha, { siteKey: '6Lc7nLYgAAAAAHhIwMydaWpLZCUCC2GT1e45EVuT' })
app.use(VueSmoothScroll)
// app.use(breadcrumbs, {
//   includeComponent: false // {boolean} [includeComponent=false] - Include global breadcrumbs component or not
// })
app.use(PrimeVue)
// app.component('DiaBreadcrumblog', Breadcrumb)
app.use(store)
app.use(router)

// registerScrollSpy(app)

app.mount('#app')
