<template>
  <div id="sponsors" class="section">
    <!-- Container Ends -->
    <div class="container">
      <!-- Row and Scroller Wrapper Starts -->
      <carousel id="sponsors-scroller" :settings="settings" :breakpoints="breakpoints">
        <slide key="0">
          <div class="sponsor-item-wrapper">
            <img src="@/assets/img/sponsors/market.jpg" alt="">
          </div>
        </slide>
        <slide key="1">
          <div class="sponsor-item-wrapper">
            <img src="@/assets/img/sponsors/social.jpg" alt="">
          </div>
        </slide>
        <slide key="2">
          <div class="sponsor-item-wrapper">
            <img src="@/assets/img/sponsors/live.jpg" alt="">
          </div>
        </slide>
        <slide key="3">
          <div class="sponsor-item-wrapper">
            <img src="@/assets/img/sponsors/entertain.jpg" alt="">
          </div>
        </slide>
        <slide key="4">
          <div class="sponsor-item-wrapper">
            <img src="@/assets/img/sponsors/blockchain.jpg" alt="">
          </div>
        </slide>
        <slide key="5">
          <div class="sponsor-item-wrapper">
            <img src="@/assets/img/sponsors/crypto.jpg" alt="">
          </div>
        </slide>
      </carousel>
    </div>
  </div>
</template>

<script>
import { Options, Vue } from 'vue-class-component'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

@Options({
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation
  },
  data: () => ({
    // carousel settings
    settings: {
      itemsToShow: 1,
      snapAlign: 'center',
      autoplay: 4000,
      transition: 1000,
      wrapAround: true
    },
    // breakpoints are mobile first
    // any settings not specified will fallback to the carousel settings
    breakpoints: {
      700: {
        itemsToShow: 3,
        snapAlign: 'start'
      },
      1199: {
        itemsToShow: 4,
        snapAlign: 'start'
      }
    }
  })
})
export default class Sponsor extends Vue {}
</script>
