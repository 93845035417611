<template>
  <!-- Start Video promo Section -->
  <section class="video-promo section">
    <div class="overlay"></div>
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-sm-12">
            <div class="video-promo-content text-center d-block d-md-none">
              <a href="https://www.youtube.com/watch?v=LdPF4ooiXvA" class="video-popup"><i class="lni-film-play"></i></a>
              <h2 class="mt-3 wow zoomIn" data-wow-duration="1000ms" data-wow-delay="100ms">Watch Video</h2>
             </div>
             <div class="video-promo-content text-center d-none d-md-block">
                <YoutubeVue3 ref="youtube" :videoid="play.video_id" :loop="play.loop" :width="680" :height="420" :autoplay="play.autoplay"/>
            </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Video Promo Section -->
</template>

<script >
import { Options, Vue } from 'vue-class-component'
import { YoutubeVue3 } from 'youtube-vue3'
// import ModalVideo from 'modal-video'
// import LazyTube from "vue-lazytube"

@Options({
  data: () => ({
    play: { video_id: 'LdPF4ooiXvA', loop: 1, autoplay: 0 }
  }),
  components: {
    YoutubeVue3
  },
  mounted () {
    // ModalVideo('.js-modal-btn', { channel: 'youtube' })
  }
})
export default class VideoPromo extends Vue {
}
</script>
